import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MobileDetect = require("mobile-detect");

export default class SinglePostSidebar {
  // 現在表示されているページでこのコンポーネントを利用するかどうか
  public isUsed: boolean = false;
  private $sidebar: HTMLDivElement;
  private $contentLayout: HTMLDivElement;
  private mobileDetect: MobileDetect;

  constructor() {
    this.$sidebar = document.querySelector(".post-sidebar-contents");
    this.$contentLayout = document.querySelector(".post-content-layout");
    this.mobileDetect = new MobileDetect(window.navigator.userAgent);
    const isMobile = !!this.mobileDetect.mobile();
    if (this.$sidebar && !isMobile) {
      this.isUsed = true;
    }
    console.log('Used Sidebar: ', this.isUsed);
  }

  public init(): void {
    ScrollTrigger.create({
      trigger: this.$contentLayout,
      start: "top top+=50",
      end: "bottom bottom",
      pin: this.$sidebar
    });
  }
}