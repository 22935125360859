import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as TomSelect from "tom-select";


export default class DaysMonthlyNavigation {
  // 現在表示されているページでこのコンポーネントを利用するかどうか
  public isUsed: boolean = false;
  public isUsedDropdown: boolean = false;
  private $openButton: HTMLLinkElement;
  private $navBox: HTMLDivElement;
  private $yearList: HTMLDivElement;
  private $monthlyNavListBox: HTMLDivElement;
  private $monthlyNavs: NodeList;
  private $yearButtons: NodeList;
  private isOpenNav: boolean = false;
  private isHoverMonthlyNav: boolean = false;
  private hoverMonthlyNavTimer: any;
  private selectedYear: string = null;
  private $selectedYearButton: HTMLLinkElement;
  private $selectedMonthlyNav: HTMLDivElement;

  constructor() {
    this.isUsed = document.body.classList.contains("category-days");
    this.isUsedDropdown = document.getElementById("app-monthly-dropdown-nav-container") ? true : false;

    if (this.isUsed) {
      console.log("Create Monthly Nav");
      this.$navBox = document.querySelector(
        ".days-monthly-navigation-component .monthly-nav-box"
      );
      this.$openButton = document.querySelector(
        ".days-monthly-navigation-component .open-nav-button"
      );
      this.$yearList = document.querySelector(
        ".days-monthly-navigation-component .years-list-box"
      );
      this.$yearButtons = document.querySelectorAll(
        ".days-monthly-navigation-component .year-select-button"
      );
      this.$monthlyNavListBox = document.querySelector(
        ".days-monthly-navigation-component .monthly-links-nav-list-box"
      );
      this.$monthlyNavs = document.querySelectorAll(
        ".days-monthly-navigation-component .monthly-links-nav"
      );
      console.log(this.$yearButtons);
    }

    console.log('Use Dropdown App: ', this.isUsedDropdown);
    if (this.isUsedDropdown) {
      // ドロップダウンメニューの実装
      const settings = {
        onChange: (value) => {
          console.log('change: ', value);
          location.href = value;
        }
      };
      // @ts-ignore
      new TomSelect('#days-select-nav', settings);
    }
  }

  public init(): void {
    if (this.isUsed) {
      this.$navBox.style.display = "none";
      this.$yearList.style.opacity = "0";
      this.$yearList.style.transform = "translateY(-30px)";
      this.$monthlyNavs.forEach(($nav: HTMLDivElement) => {
        $nav.style.opacity = "0";
        $nav.style.transform = "translateY(-30px)";
        $nav.style.display = 'none';
      });

      this.$openButton.addEventListener("mouseenter", this.openYearNav, false);
      this.$yearButtons.forEach(($button) => {
        $button.addEventListener("mouseenter", this.onYearButtonEnter);
        $button.addEventListener("mouseleave", this.onYearButtonLeave);
      });
    }
  }

  private initialize = (): void => {
    console.log("Initialize Nav");
    if (this.isUsed) {
      if (this.selectedYear) {
      }
      this.$yearList.style.opacity = "0";
      this.$yearList.style.transform = "translateY(-30px)";
      this.$monthlyNavs.forEach(($nav: HTMLDivElement) => {
        $nav.style.opacity = "0";
        $nav.style.transform = "translateY(-30px)";
      });
      this.$navBox.style.display = "none";
      this.$navBox.style.opacity = "1";
    }
  };

  private openYearNav = (): void => {
    if (!this.isOpenNav) {
      this.isOpenNav = true;
      this.$navBox.style.display = "block";
      gsap.fromTo(
        this.$yearList,
        {
          opacity: 0,
          y: -30,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.4,
          ease: "power3.out",
          onComplete: () => {
            this.$navBox.addEventListener("mouseleave", this.closeNav, false);
          },
        }
      );
    }
  };

  private closeNav = (): void => {
    console.log("mouseleave");
    if (this.isOpenNav) {
      this.isOpenNav = false;
      this.$navBox.removeEventListener("mouseleave", this.closeNav);
      gsap.to(this.$navBox, {
        opacity: 0,
        duration: 0.2,
        ease: "linear",
        onComplete: () => {
          this.initialize();
        },
      });
    }
  };

  private onYearButtonEnter = (ev: MouseEvent): void => {
    if (this.hoverMonthlyNavTimer) {
      clearTimeout(this.hoverMonthlyNavTimer);
      this.hoverMonthlyNavTimer = null;
    }
    const $selectedButton: HTMLLinkElement = <HTMLLinkElement>ev.target;
    // 同じ年だった場合は処理しない
    if (
      this.selectedYear &&
      this.selectedYear === $selectedButton.dataset.year
    ) {
      return;
    }

    // すでに選択されている年がある場合
    if (this.selectedYear && this.$selectedYearButton) {
      this.$selectedYearButton.classList.remove("selected");
      this.selectedYear = null;
      this.$selectedYearButton = null;
      this.$selectedMonthlyNav.removeEventListener('mouseenter', this.onMonthlyNavEnter);
      this.$selectedMonthlyNav.removeEventListener('mouseleave', this.onMonthlyNavLeave);
      
      gsap.killTweensOf(this.$selectedMonthlyNav);
      gsap.set(this.$selectedMonthlyNav, {
        opacity: 0,
        y: -30
      });
      this.$selectedMonthlyNav.style.display = 'none';
    }

    this.selectedYear = $selectedButton.dataset.year;
    console.log('Selected: ', this.selectedYear);
    $selectedButton.classList.add("selected");
    this.$selectedYearButton = $selectedButton;
    this.$monthlyNavs.forEach(($nav: HTMLDivElement) => {
      if ($nav.dataset.year === this.selectedYear) {
        this.$selectedMonthlyNav = $nav;
      }
    });
    this.$selectedMonthlyNav.style.display = 'flex';
    this.$selectedMonthlyNav.addEventListener("mouseenter", this.onMonthlyNavEnter);
    this.$selectedMonthlyNav.addEventListener("mouseleave", this.onMonthlyNavLeave);
    gsap.fromTo(
      this.$selectedMonthlyNav,
      {
        opacity: 0,
        y: -30,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.4,
        ease: "power3.out",
      }
    );
  };

  private onYearButtonLeave = (ev): void => {
    if (this.hoverMonthlyNavTimer) {
      clearTimeout(this.hoverMonthlyNavTimer);
    }
    this.hoverMonthlyNavTimer = setTimeout(() => {
      this.hoverMonthlyNavTimer = null;
      if (!this.isHoverMonthlyNav) {
        if (this.selectedYear && this.$selectedYearButton) {
          this.$selectedYearButton.classList.remove("selected");
          this.selectedYear = null;
          this.$selectedYearButton = null;
          this.$selectedMonthlyNav.removeEventListener(
            "mouseenter",
            this.onMonthlyNavEnter
          );
          this.$selectedMonthlyNav.removeEventListener(
            "mouseleave",
            this.onMonthlyNavLeave
          );
          gsap.to(this.$selectedMonthlyNav, {
            opacity: 0,
            y: -30,
            duration: 0.2,
            ease: "linear",
            onComplete: () => {
              this.$selectedMonthlyNav.style.display = 'none';
            }
          });
        }
      }
    }, 100);
  };

  private onMonthlyNavEnter = (ev): void => {
    this.isHoverMonthlyNav = true;
  };

  private onMonthlyNavLeave = (ev): void => {
    this.isHoverMonthlyNav = false;
    this.onYearButtonLeave(null);
  };
}