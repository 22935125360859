import "./styles/style.scss";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import CustomEase from "gsap/CustomEase";
import DaysMonthlyNavigation from "./components/DaysMonthlyNavigation";
import SinglePostSidebar from "./components/SinglePostSidebar";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollSmoother);
gsap.registerPlugin(CustomEase);

export class Main {
  protected daysMonthlyNav: DaysMonthlyNavigation;
  protected postSidebar: SinglePostSidebar;

  private scroller: ScrollSmoother;

  constructor() {
    this.daysMonthlyNav = new DaysMonthlyNavigation();
    this.postSidebar = new SinglePostSidebar();

    this.scroller = ScrollSmoother.create({
      smooth: 1,
      effects: true
    });
  }

  public init(): void {
    console.log("Initialize Main");
    if (this.daysMonthlyNav.isUsed) {
      this.daysMonthlyNav.init();
    }
    if (this.postSidebar.isUsed) {
      this.postSidebar.init();
    }
  }
}

let main: Main;

document.addEventListener("DOMContentLoaded", () => {
  console.log("<<<DOM Content Loaded Action");
  main = new Main();
});

window.addEventListener("load", () => {
  console.log("<<<Window Loaded Action");
  main.init();
});
